import { Formik } from 'formik';
import { isObject } from 'lodash-es';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import { DialogActionWrapper } from '../../../../common/components/DialogComponent/index.styled';
import UploadPicture from '../../../../common/components/UploadPicture';

import {
  isAcceleratorSelector,
  isMentorSelector,
} from '../../../../store/auth/login';

import { SaveIconOutlined } from '../../../../assets/icons';

import InputField from '../../../../fields/InputField';

const AddPersonInfoForm = (props) => {
  const { onSubmit, personInfo = {} } = props;
  const intl = useIntl();

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const schema = Yup.object({
    name: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    description: Yup.string().required(intl.formatMessage({ id: 'validate.mandatoryField' })),
    ...(!personInfo?.profilePicture
      ? { uploadedFile: Yup.mixed().required(intl.formatMessage({ id: 'validate.mandatoryField' })) }
      : {}),
  });

  const initialValues = {
    name: personInfo?.name || '',
    description: personInfo?.description || '',
    uploadedFile: null,
    profilePicture: personInfo?.profilePicture || '',
  };

  const handleFilesSelected = (files, setFieldValue) => {
    setFieldValue('uploadedFile', files[0]);
    setFieldValue('profilePicture', URL.createObjectURL(files[0]));
  };

  const handleDeleteProfileImage = (setFieldValue) => {
    setFieldValue('uploadedFile', '');
    setFieldValue('profilePicture', '');
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate>
          <UploadPicture
            url={values.profilePicture}
            onSelect={(files) => handleFilesSelected(files, setFieldValue)}
            onDelete={() => handleDeleteProfileImage(setFieldValue)}
            sx={{ mb: 15 }}
          />
          <InputField id="name" label={intl.formatMessage({ id: 'label.name' })} />
          <InputField id="description" label={intl.formatMessage({ id: 'label.description' })} />
          <DialogActionWrapper>
            <ButtonComponent
              type="submit"
              icon={<SaveIconOutlined size="16px" />}
              color="secondary"
              label={intl.formatMessage({ id: 'button.save' })}
            />
          </DialogActionWrapper>
        </form>
      )}
    </Formik>
  );
};
const mapStateToProps = (state) => ({
  hypotheses: state.hypotheses.hypotheses,
  defaultColumnBMC:
    isObject(state.common.modal.props) &&
    state.common.modal.props.initialValues.columnBMC,
  isMentor: isMentorSelector(state.account),
  isAccelerator: isAcceleratorSelector(state.account),
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddPersonInfoForm);
