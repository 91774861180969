export const getMrlProgress = (mrls, teamTasks) => {
  let totalTasks = 0;
  let completedTasks = 0;

  if (!mrls?.length || !teamTasks?.length) {
    return 0;
  }

  mrls?.forEach(({ tasks }) => totalTasks += tasks?.length);

  teamTasks?.forEach(({ isDone, taskId }) => {
    const found = mrls?.some(({ tasks }) => tasks?.some(({ _id }) => _id === taskId));

    if (isDone && found) {
      completedTasks += 1;
    }
  });

  return completedTasks > 0
    ? completedTasks * 100 / totalTasks
    : 0;
};

export const calculateCompletionStatus = (currentTeam, currentStepId, tasksLength, mrls) => {
  let completedTasks = 0;

  currentTeam?.mrl?.forEach(({ stepId, taskId, isDone }) => {
    const found = mrls?.some(({ tasks }) => tasks?.some(({ _id }) => _id === taskId));

    if (found && currentStepId === stepId && isDone) {
      completedTasks += 1;
    }
  });

  return `${completedTasks}/${tasksLength}`;
};
