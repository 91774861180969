import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import DrawerTabs from '../../../../common/components/DrawerTabs';
import DrawerTitle from '../../../../common/components/DrawerTitle';
import GenericComments from '../../../commentsAndNotifications/components/GenericComments';
import ActivityDetails from '../../components/ActivityDetails';

import { currentTeamIdSelector } from '../../../../common/store';
import { getAllComments } from '../../../commentsAndNotifications/store';

import { DRAWER_TABS } from '../../../../constants/drawerTabs';
import { HYPOTHESIS_COMMENTS_TYPES_BY_TAB } from '../../../commentsAndNotifications/constants';

const ActivityDetailsDrawer = ({
  handleCloseDrawer,
  activity,
  isLoadingResources,
  tasks,
  conclusions,
  handleEdit,
  handleDelete,
  responsable,
  setIsAddTaskModalOpen,
  handleToggleTask,
  setIsAddConclusionModalOpen,
  setPreview,
  setTaskDetailModalId,
  isSubmittingTask,
  isSubmittingConclusion,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [activeDrawerTab, setActiveDrawerTab] = useState(DRAWER_TABS.details);

  const currentUserId = useSelector((state) => state.userProfile?.userProfile?.userId?._id);
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const comments = useSelector((state) => state.comments[HYPOTHESIS_COMMENTS_TYPES_BY_TAB.activities]) || [];
  const hypothesis = useSelector((state) => state.hypotheses?.hypothesis);

  const fetchComments = () => {
    dispatch(
      getAllComments({
        filterParams: {
          commentType: HYPOTHESIS_COMMENTS_TYPES_BY_TAB.activities,
          entityId: hypothesis._id,
        },
        teamId,
        userId: currentUserId,
      })
    );
  };

  return (
    <>
      <DrawerTitle
        title={intl.formatMessage({ id: 'label.visualizeActivity' })}
        onClose={handleCloseDrawer}
      />
      <DrawerTabs
        sx={{ mt: 4 }}
        active={activeDrawerTab}
        onClick={setActiveDrawerTab}
      />
      {activeDrawerTab === DRAWER_TABS.details
        ? (
          <ActivityDetails
            activity={activity}
            isLoadingResources={isLoadingResources}
            tasks={tasks}
            conclusions={conclusions}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            responsable={responsable}
            setIsAddTaskModalOpen={setIsAddTaskModalOpen}
            handleToggleTask={handleToggleTask}
            setIsAddConclusionModalOpen={setIsAddConclusionModalOpen}
            setPreview={setPreview}
            setTaskDetailModalId={setTaskDetailModalId}
            isSubmittingTask={isSubmittingTask}
            isSubmittingConclusion={isSubmittingConclusion}
          />
        ) : (
          <GenericComments
            fetchComments={fetchComments}
            comments={comments}
            teamId={teamId}
            userId={currentUserId}
            type={HYPOTHESIS_COMMENTS_TYPES_BY_TAB.activities}
            entityId={hypothesis._id}
          />
        )}
    </>
  );
};

export default ActivityDetailsDrawer;
