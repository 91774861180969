import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { loadUserTeams } from '../../../../common/store';
import { putFinishQuestionnaire } from '../../../../requests/chestionar';
import { loadProfile } from '../../../../store/userProfile/userProfileSlice';

import { handleApiError } from '../../../../utils/errorUtils';

import { Content } from '../../index.styled';

const ConfirmationStep = ({ userProfile }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedUser = useSelector((state) => state.userProfile.userProfile?.userId);

  useEffect(() => {
    if (loggedUser?._id) {
      setTimeout(() => handleFinishQuestionnaire(), 5000);
    }
  }, [loggedUser?._id]);

  const handleFinishQuestionnaire = async () => {
    try {
      await putFinishQuestionnaire();
      await dispatch(loadUserTeams(loggedUser?._id));
      await dispatch(loadProfile());
      history.push('/');
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <Content>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="70vh"
      >
        <Typography variant="h1" color="primary" gutterBottom>
          {intl.formatMessage(
            { id: 'label.congratsYourAccountIsReady' },
            { name: userProfile.firstName },
          )}
        </Typography>
      </Box>
    </Content>
  );
};

export default ConfirmationStep;
