import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import ButtonComponent from '../../../../common/components/ButtonComponent';
import DialogAlert from '../../../../common/components/DialogAlert';
import AddHypothesisModal from '../../../../common/modals/AddHypothesisModal';
import HypothesesIndicators from '../../components/HypothesisIndicators';
import HypothesisInfo from '../../components/HypothesisInfo';
import HypothesisSegments from '../../components/HypothesisSegments';

import { currentTeamIdSelector } from '../../../../common/store';
import {
  deleteHypothesis,
  editHypothesis,
  loadHypotheses,
  loadHypothesis,
} from '../../../../store/hypothesis/hypothesisSlice';
import {
  createTargetIndicators,
  deleteTargetIndicator,
  editTargetIndicator,
  getAllTargetIndicators,
} from '../../../../store/targetIndicators/targetIndicatorsSlice';

import { BMC_TYPE } from '../../../../constants/bmc';
import { DIMENSION_SEGMENT_INDICATORS } from '../../../../constants/targetIndicators';
import { canManageHypothesis } from '../../../../utils/permissions';

import { EditIcon, TrashIconOutlined } from '../../../../assets/icons';

import { Root } from './index.styled';

const HypothesisDescription = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { hypothesisId } = useParams();
  const hypothesis = useSelector((state) => state.hypotheses.hypothesis);
  const hypotheses = useSelector((state) => state.hypotheses.hypotheses) || [];
  const firstName = useSelector((state) => state.userProfile.userProfile?.firstName);
  const lastName = useSelector((state) => state.userProfile.userProfile?.lastName);
  const teamId = useSelector((state) => currentTeamIdSelector(state.common.userTeams));
  const targetIndicators = useSelector((state) => state.targetIndicators.targetIndicators || []);
  const [isHypothesisModalOpen, setIsHypothesisModalOpen] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);

  const filteredTargetIndicators = useMemo(() => (
    targetIndicators?.filter(({ targetIndicatorType }) => !DIMENSION_SEGMENT_INDICATORS.includes(targetIndicatorType))
  ), [targetIndicators]);

  useEffect(() => {
    if (teamId) {
      dispatch(loadHypotheses(teamId));
    }
  }, [teamId]);

  const handleDeleteHypothesis = async () => {
    const response = await dispatch(deleteHypothesis(hypothesisId));
    if (response.status === 200) {
      await Promise.all(
        filteredTargetIndicators.map((targetIndicator) =>
          dispatch(deleteTargetIndicator(targetIndicator._id))
        )
      );
      history.push('/canvas');
    }
  };

  const segmentOptions = useMemo(() => (
    hypotheses
      ?.filter(({columnBMC }) => columnBMC === BMC_TYPE.customerSegments)
      ?.map(({title, _id, color}) => ({
        label: `${title}`,
        value: `${_id}`,
        color: `${color}`,
      }))
  ), [hypotheses]);

  const segmentsHypothesis  = useMemo(() => (
    hypotheses?.filter(({ _id }) => hypothesis?.segment?.includes(_id))
  ), [hypotheses, hypothesis?.segment]);

  const handleTargetIndicators = async (values) => {
    const promises = [];

    for (let i = 0; i < values?.targetIndicators?.length; i += 1) {
      const targetIndicator = values?.targetIndicators[i];

      if (!targetIndicator?._id) {
        const createIndicatorPayload = {
          targetIndicators: [targetIndicator],
          hypothesisId: hypothesis._id,
        };
        promises.push(await dispatch(createTargetIndicators(createIndicatorPayload)));
      }

      if (targetIndicator?._id) {
        const foundIndicator = filteredTargetIndicators?.find(({ _id }) => _id === targetIndicator?._id);

        if (!isEqual(foundIndicator, targetIndicator)) {
          const payload = {
            targetIndicatorType: targetIndicator?.targetIndicatorType,
            value: targetIndicator?.value,
            unitOfMeasurement: targetIndicator?.unitOfMeasurement,
          };
          promises.push(await dispatch(editTargetIndicator(targetIndicator._id, payload)));
        }
      }
    }

    for (let i = 0; i < filteredTargetIndicators?.length; i += 1) {
      const { _id } = filteredTargetIndicators[i];
      const foundIndicator = values?.targetIndicators?.some((indicator) => indicator?._id === _id);

      if (!foundIndicator) {
        promises.push(await dispatch(deleteTargetIndicator(_id)));
      }
    }

    return Promise.all(promises);
  };

  const onEditHypothesis = async (values) => {
    const createdBy = `${firstName} ${lastName}`;

    const payload = {
      title: values.title,
      createdBy,
      description: values.description,
      columnBMC: values.columnBMC,
      importance: values.importance,
      segment: values.segment,
      hypothesisStage: values.hypothesisStage,
      hypothesisCategory: hypothesis.hypothesisCategory,
      ...(values.subscriptionType ? { subscriptionType: values.subscriptionType } : {}),
    };

    setIsHypothesisModalOpen(false);
    await dispatch(editHypothesis(hypothesisId, payload));
    await dispatch(loadHypothesis(hypothesisId));
    await handleTargetIndicators(values);
    await dispatch(getAllTargetIndicators(teamId, hypothesisId));
  };

  useEffect(() => {
    if (teamId && hypothesisId) {
      dispatch(getAllTargetIndicators(teamId, hypothesisId));
    }
  }, [hypothesisId, teamId]);

  const handleCloseModal = () => setIsHypothesisModalOpen(false);

  const handleEdit = () => setIsHypothesisModalOpen(true);

  const handleOpenDeleteDialog = () => setOpenAlertMessage(true);

  const handleCloseAlert = () => setOpenAlertMessage(false);

  return (
    <Root>
      <Box display="flex" flexDirection="column" gap={20} width="700px">
        <HypothesisInfo hypothesis={hypothesis} />

        {segmentsHypothesis?.length > 0 && (
          <HypothesisSegments segmentsHypothesis={segmentsHypothesis} />
        )}

        {filteredTargetIndicators?.length > 0 && (
          <HypothesesIndicators targetIndicators={filteredTargetIndicators} />
        )}

        <Divider sx={{ borderColor: 'general.accentLight2' }} />

        {canManageHypothesis(loggedUser) && (
          <Box display="flex" alignItems="center" gap={10}>
            <ButtonComponent
              icon={<EditIcon fill="currentColor" size="14px" />}
              onClick={handleEdit}
              label={intl.formatMessage({ id: 'button.edit' })}
              variant="outlined"
            />
            <ButtonComponent
              onClick={handleOpenDeleteDialog}
              icon={<TrashIconOutlined size="16px" />}
              color="error"
              variant="outlined"
              label={intl.formatMessage({ id: 'button.deleteHypothesis' })}
            />
          </Box>
        )}
      </Box>

      <DialogAlert
        isModalOpen={openAlertMessage}
        onClose={handleCloseAlert}
        title={intl.formatMessage({ id: 'modal.title.deleteHypothesis' })}
        content={intl.formatMessage({ id: 'modal.content.deleteHypothesis' })}
        hasActions
        onSubmit={handleDeleteHypothesis}
      />

      <AddHypothesisModal
        isModalOpen={isHypothesisModalOpen}
        setIsModalOpen={setIsHypothesisModalOpen}
        segmentOptions={segmentOptions}
        handleClose={handleCloseModal}
        handleSubmitHypothesis={onEditHypothesis}
        hypothesisFormValues={{...hypothesis, targetIndicators: [...filteredTargetIndicators]}}
        isEdit
      />
    </Root>
  );
};

export default HypothesisDescription;
