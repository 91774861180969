import { Grid, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';

import ButtonComponent from '../../../../common/components/ButtonComponent';

import { truncateText } from '../../../../utils/sizeComments';

import { AddIcon, EditIcon } from '../../../../assets/icons';

import { CardMedia, Content } from './index.styled';

const PersonDetails = ({
  hypothesis,
  setIsPersonInfoModalOpen,
  isAllowedToManageHypothesis,
}) => {
  const intl = useIntl();

  const handleOpen = () => setIsPersonInfoModalOpen(true);

  if (!hypothesis?.clientProfile) {
    return isAllowedToManageHypothesis ? (
      <Box display="flex" justifyContent="flex-end" onClick={handleOpen} mb={20}>
        <ButtonComponent
          variant="contained"
          label={intl.formatMessage({ id: 'button.addProfile' })}
          icon={<AddIcon fill='currentColor' width='22px' height='22px' />}
        />
      </Box>
    ) : null;
  }

  return (
    <Grid container id="middle-container" spacing={20} mb={20}>
      {hypothesis?.clientProfile?.profilePicture && (
        <Grid item xs={12} lg={2}>
          <CardMedia
            component="img"
            src={hypothesis?.clientProfile?.profilePicture}
            alt="Client Profile"
          />
        </Grid>
      )}

      <Grid item xs={12} lg={10}>
        <Content>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={10} mb={4}>
            <Typography variant="h2" color="primary" fontWeight={600}>
              {truncateText(hypothesis?.clientProfile?.name, 15)}
            </Typography>
            {isAllowedToManageHypothesis && (
              <IconButton onClick={handleOpen} color="primary">
                <EditIcon size={18} />
              </IconButton>
            )}
          </Box>
          <Typography variant="body1" color="textSecondary">
            {truncateText(hypothesis?.clientProfile?.description, 18)}
          </Typography>
        </Content>
      </Grid>
    </Grid>
  );
};

export default PersonDetails;
