import { Box, IconButton, useMediaQuery, useTheme, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { TeamFilterForm } from '../../forms';

import { setIsOpen } from '../../../modules/mrl/store/mrl';
import { isAdminSelector } from '../../../store/auth/login';
import { setCurrentUserTeam } from '../../store';
import { currentTeamSelector, userTeamsSelector } from '../../store/userTeamsSelector';

import { ThermostatIcon, MenuIcon } from '../../../assets/icons';
import { NotificationsNavIcon } from '../../../assets/icons/navbar';

import Mrl from '../../../modules/mrl/containers/Mrl';
import DrawerComponent from '../DrawerComponent';
import { Root } from './index.styled';
import LanguageSelector from './LanguageSelector';
import User from './User';

const Navbar = ({ onClickMenu }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const intl = useIntl();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const userTeams = useSelector(userTeamsSelector);
  const currentTeam = useSelector(currentTeamSelector);
  const isAdmin = useSelector((state) => isAdminSelector(state?.account));
  const userId = useSelector((state) => state?.userProfile?.userProfile?.userId?._id);
  const unreadComments = useSelector((state) => state.comments.unreadComments);
  const isMrlOpen = useSelector((state) => state.mrl.isOpen);

  const handleRedirectNotifications = () => history.push('/notifications');

  const teamsAsOptions = useMemo(() => (
    userTeams?.map((team) => ({
      label: team.name,
      value: team._id,
    }))
  ), [userTeams]);

  const handleChangeTeam = (team) => dispatch(setCurrentUserTeam(userId, team.value));

  const currentTeamOption = useMemo(() => ({
    label: currentTeam?.name,
    value: currentTeam?._id,
    logo: currentTeam?.logo,
  }), [currentTeam]);

  const handleOpenDrawer = () => dispatch(setIsOpen(true));

  const handleCloseDrawer = () => dispatch(setIsOpen(false));

  if (isAdmin) {
    return (
      <Root justifyContent="flex-end">
        <LanguageSelector />
        <User />
      </Root>
    );
  }

  return (
    <Root justifyContent={isTablet ? 'space-between': 'flex-end'}>
      {isTablet && (
        <IconButton color="primary" sx={{ p: 0 }} onClick={onClickMenu}>
          <MenuIcon />
        </IconButton>
      )}

      <Box display="flex" alignItems="center" gap={8}>
        <TeamFilterForm
          userTeams={teamsAsOptions}
          userTeamValue={currentTeamOption}
          handleChangeTeam={handleChangeTeam}
          cohortId={currentTeam?.cohortId}
        />
        <Tooltip
          id="tooltip-mrlGuide"
          title={intl.formatMessage({ id: 'label.mrlGuide' })}
          placement="bottom"
        >
          <IconButton color="primary" onClick={handleOpenDrawer} id="btn-mrlGuide">
            <ThermostatIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          id="tooltip-notifications"
          title={intl.formatMessage({ id: 'label.notifications' })}
          placement="bottom"
        >
          <IconButton onClick={handleRedirectNotifications} id="notifications-bell">
            <NotificationsNavIcon unread={unreadComments} />
          </IconButton>
        </Tooltip>
        <User />
      </Box>

      {isMrlOpen && (
        <DrawerComponent isOpen={isMrlOpen} onClose={handleCloseDrawer} sx={{ zIndex: 199 }}>
          <Mrl />
        </DrawerComponent>
      )}
    </Root>
  );
};

export default Navbar;
