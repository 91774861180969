import { IconButton, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { isNil } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import AccordionComponent from '../../../../common/components/AccordionComponent';
import Iframe from '../../../../common/components/Iframe';
import { Loading } from '../../../../common/styled';
import LinearProgressWithLabel from '../../components/LinearProgressWithLabel';
import MrlStep from '../../components/MrlStep';
import MrlTask from '../../components/MrlTask';
import {
  MrlContainer,
  MrlHeader,
  MrlStepContainer,
} from '../../styled';

import { loadUserTeams } from '../../../../common/store';
import { currentTeamSelector } from '../../../../common/store/userTeamsSelector';
import { editTeamMrlTask } from '../../../../requests/teams';
import { loadMrl } from '../../store';

import { NOT_APPLICABLE } from '../../../../constants/general';
import { handleApiError } from '../../../../utils/errorUtils';
import { compareOrder } from '../../../../utils/sort';
import { calculateCompletionStatus, getMrlProgress } from '../../utils';

import { ChevronLeft, ThermostatIcon } from '../../../../assets/icons';

const Mrl = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();

  const currentTeam = useSelector(currentTeamSelector) || {};
  const mrlData = useSelector((state) => state.mrl.mrls) || [];
  const isLoading = useSelector((state) => state.mrl.isLoading) || false;
  const loggedUser = useSelector((state) => state.userProfile.userProfile && state.userProfile.userProfile);
  const userLanguage = useSelector(state => state?.userProfile?.userProfile?.language);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedTaskToOpen, setSelectedTaskToOpen] = useState();

  useEffect(() => {
    dispatch(loadMrl());
  }, []);

  const sortItems = (list) => list.sort(compareOrder);

  const sortedMRL = useMemo(() => sortItems(mrlData), [mrlData]);

  const handleMrlTaskOpen = (task) => {
    if (task === selectedTaskToOpen) {
      setSelectedTaskToOpen('');
      return;
    }

    setSelectedTaskToOpen(task);
  };

  const handleBack = (e) => {
    setSelectedTaskToOpen('');
    setSelectedIndex(null);
    e.stopPropagation();
  };

  const handleCheckbox = async (event, task) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      const payload = {
        stepId: mrlData[selectedIndex]?._id,
        taskId: task?._id,
        isDone: event?.target?.checked,
      };
      await editTeamMrlTask(payload, currentTeam?._id);
      dispatch(loadUserTeams(loggedUser?.userId?._id, true));
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <>
      <MrlContainer id="mrl-container">
        <MrlHeader>
          {isNil(selectedIndex) ? (
            <>
              <ThermostatIcon fill={theme.palette.primary.main} />
              <Typography variant="h3" color="primary" ml={10} fontWeight={600}>
                {intl.formatMessage({ id: 'label.mrlGuide' })}
              </Typography>
            </>
          ) : (
            <>
              <IconButton color="primary" sx={{ p: 0 }} onClick={handleBack}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="h3" color="primary" className="text-ellipsis" ml={5} fontWeight={600}>
                {intl.formatMessage({ id: 'label.backToMRL' })}
                {' | '}
                {calculateCompletionStatus(
                  currentTeam,
                  mrlData[selectedIndex]?._id,
                  mrlData[selectedIndex]?.tasks?.length,
                  mrlData,
                )}
              </Typography>
            </>
          )}
        </MrlHeader>

        <LinearProgressWithLabel
          value={getMrlProgress(mrlData, currentTeam?.mrl)}
          sx={{ mt: 10, ml: 0 }}
        />

        <MrlStepContainer className="hidden-scroll">
          {isNil(selectedIndex)
            ? sortedMRL.map((item, index) => (
              <div
                key={index}
                id={`mrl-${index}`}
                onClick={() => setSelectedIndex(index)}
              >
                <MrlStep
                  number={index}
                  step={item}
                  completionStatus={calculateCompletionStatus(currentTeam, item?._id, item?.tasks?.length, mrlData)}
                />
              </div>
            )) : (
              <Box>
                <AccordionComponent
                  title={`${selectedIndex + 1}. ${mrlData[selectedIndex].name?.[userLanguage] || NOT_APPLICABLE}`}
                  pl={0}
                  variant="h3"
                  arrowPlacement="end"
                  fullWidth
                >
                  <Iframe
                    title="Preview"
                    content={mrlData[selectedIndex].description?.[userLanguage]}
                    hasDynamicHeight
                  />
                </AccordionComponent>

                {mrlData[selectedIndex]?.tasks?.length > 0 && (
                  <Box display="flex" flexDirection="column" gap={12} mt={10}>
                    {mrlData[selectedIndex].tasks.map((task) => {
                      const isDone = currentTeam?.mrl?.find(({ taskId }) => taskId === task?._id)?.isDone || false;

                      return (
                        <MrlTask
                          key={task._id}
                          isDone={isDone}
                          task={task}
                          buttonName={task.buttonName?.[userLanguage]}
                          isOpen={selectedTaskToOpen === task.name?.[userLanguage]}
                          onMrlTaskOpen={handleMrlTaskOpen}
                          onCheckTask={(e) => handleCheckbox(e, task)}
                        />
                      );
                    })}
                  </Box>
                )}
              </Box>
            )}
        </MrlStepContainer>
      </MrlContainer>
      <Loading isLoading={isLoading} />
    </>
  );
};

export default Mrl;
